import React from 'react';
import Hero from '../Hero';
import AboutSection from '../Section/AboutSection';
import BrandsSection from '../Section/BrandsSection';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';
import TestimonialSection from '../Section/TestimonialSection';
import BlogSection from '../Section/BlogSection';
import AppointmentSection from '../Section/AppointmentSection';
import FaqSection from '../Section/FaqSection';
import AwardSection from '../Section/AwardSection';
import DepartmentSection from '../Section/DepartmentSection';
import { pageTitle } from '../../helpers/PageTitle';
const featureListData = [
  {
    iconSrc: '/images/home_1/compassion.svg',
    title: 'Compassion',
    subTitle:
      'We understand that receiving in-home care can be a stressful and emotional experience, so we strive to create a welcoming and supportive environment that puts you at ease and ensures your comfort.',
  },
  {
    iconSrc: '/images/home_1/excellence.svg',
    title: 'Excellence',
    subTitle:
      'We are committed to providing excellent in-home care and services to our clients. We believe in continuously improving our skills, knowledge, and resources to ensure that we deliver the highest quality support possible.',
  },
  {
    iconSrc: '/images/home_1/integrity.svg',
    title: 'Integrity',
    subTitle: `We believe in providing in-home care with integrity and honesty. We are transparent in our communication and decision-making processes, always putting your interests first to offer the best possible solutions.`,
  },
  {
    iconSrc: '/images/home_1/respect.svg',
    title: 'Respect',
    subTitle:
      'We treat all individuals with respect and dignity, regardless of their background, beliefs, or circumstances. We believe that every person deserves to be treated with compassion and kindness.',
  },
  {
    iconSrc: '/images/home_1/teamwork.svg',
    title: 'Teamwork',
    subTitle:
      'We believe in working collaboratively with our team members and other healthcare professionals to provide comprehensive and effective in-home care to our clients.',
  },
];
const brandData = [
  { imgUrl: 'images/brand_1.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_2.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_3.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_4.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_5.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_6.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_7.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_8.png', imgAlt: 'Brand' },
];
const faqData = [
  {
    title: 'What services does MyCarity offer?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'How do I schedule an appointment with a Provider?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'Do you accept insurance?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'Where can I find the lists of qualified Providers?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
  {
    title: 'How do I request a for a specific task to a Provider?',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
  },
];
const blogData = [
  {
    title: 'The Benefits of Mindfulness Meditation for Stress and Anxiety',
    thumbUrl: 'images/home_1/post_1.jpeg',
    date: 'May 1, 2023',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Healthy Eating on a Budget: Tips and Strategies',
    thumbUrl: 'images/home_1/post_2.jpeg',
    date: 'May 4, 2023',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'The Importance of Regular Cancer Screenings and Early Detection',
    thumbUrl: 'images/home_1/post_3.jpeg',
    date: 'May 1, 2023',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
];
const awardData = [
  {
    title: 'SSN Trace',
    subTitle:
      'Is a component of a background check that uses an individual Social Security Number (SSN) to gather information about their identity and past addresses. It helps verify that the SSN is valid and matches the person in question. ',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'National Criminal Search (Standard)',
    subTitle:
      'Is a comprehensive background check that involves searching multiple databases and sources across the country to identify any criminal records associated with an individual.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Sex Offender Search ',
    subTitle:
      'A process that identifies whether an individual has been convicted of sex-related crimes and is listed on a sex offender registry. A sex offender registry is a system in various countries designed to allow government authorities to keep track of the offenders activities.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Global Watchlist Search ',
    subTitle:
      'Searches various government and regulatory databases that list individuals who are either prohibited from certain industries, such as healthcare and finance, or on a Most Wanted criminal list.',
    iconUrl: 'images/icons/award.svg',
  },
];
const departmentData = [
  {
    title: 'Emergency Department',
    iconUrl: 'images/home_1/department_icon_1.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Pediatric Departement',
    iconUrl: 'images/home_1/department_icon_2.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Gynecology Department',
    iconUrl: 'images/home_1/department_icon_3.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Cardiology Department',
    iconUrl: 'images/home_1/department_icon_4.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Neurology Department',
    iconUrl: 'images/home_1/department_icon_5.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Psychiatry Department',
    iconUrl: 'images/home_1/department_icon_6.svg',
    href: '/departments/department-details',
  },
];

export default function Home() {
  pageTitle('Home');
  return (
    <>
      <Hero
        title="Your Complete <br>In-Home Care Solutions"
        subTitle="We are dedicated to delivering compassionate, personalized <br> in-home care that empowers you to thrive in the comfort of <br> your own home."
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/hero_img.png"
        videoBtnText="See how we work"
        videoUrl="https://www.youtube.com/embed/Ey8CRowoDk0"
        infoList={[
          {
            title: 'Anchorage',
            subTitle: '+1 (907) 343-9131',
            iconUrl: '/images/icons/pin.svg',
            //iconUrl: '/images/contact/icon_1.svg',
          },
          {
            title: 'Soldotna',
            subTitle: '+1 907 416 6767',
            iconUrl: '/images/icons/pin.svg',
            //iconUrl: '/images/icons/ambulance.svg',
          },
          {
            title: 'Fairbanks',
            subTitle: '+1 907-336-2273',
            iconUrl: '/images/icons/pin.svg',
          },
        ]}
        btnText="Book Now"
        btnUrl="/contact"
      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Our Values" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/home_1/about.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About Us"
          subTitle="MYCARITY"
          featureList={[
            {
              featureListTitle:
                'MyCarity is a team of experienced personnels',
              featureListSubTitle:
                'We believe in a holistic approach to in-home care, focusing on nurturing the entire environment, not just the individual receiving care. Our commitment is to provide top-quality, comprehensive support that enhances every aspect of your home life.',
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Departments Section */}
      {/* <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Skills Category"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section> */}

      {/* End Departments Section */}
      {/* Start Award Section */}
      <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Basic Background Check" data={awardData} />
      </Section>
      {/* End Award Section */}
      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of our clients on our App"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="Don’t Let Your Loved Ones Take a Backseat!"
          subTitle="Schedule an in-home care with one of our experienced care provider today!"
        />
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section */}
      {/* <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogData}
        />
      </Section> */}
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      {/* <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section> */}
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      {/* <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection data={brandData} />
      </Section> */}
      {/* End Brand Section */}
    </>
  );
}
